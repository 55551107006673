import React, { useState, createRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import parse from "html-react-parser"
import ButtonExternal from "../../components/global/ButtonExternal"
import Clock from "../../assets/clock.inline.svg"
import Phone from "../../assets/phone.inline.svg"
import Marker from "../../assets/marker.inline.svg"
import ProfileEditorForm from "./ProfileEditorForm"
import { GrFacebook } from "react-icons/gr"
import { GrTwitter } from "react-icons/gr"
import { GrInstagram } from "react-icons/gr"
import { GrYoutube } from "react-icons/gr"
import { GrContactInfo } from "react-icons/gr"
import ClaimBusinessModal from "./ClaimBusinessModal"
import RellaxWrapper from "react-rellax-wrapper"
import { BsArrowDown } from "react-icons/bs"

const Hero = styled.section`
  width: 100%;
  position: relative;
  background: var(--brandColor);
  overflow: hidden;
  padding: 2rem 0;

  @media only screen and (min-width: 600px) {
    display: flex;
    padding: 4rem 0;
  }

  @media only screen and (min-width: 1024px) {
    background: var(--black);
    min-height: 750px;
    height: calc(100vh - var(--headerHeight));
    height: calc((var(--vh, 1vh) * 100) - var(--headerHeight));
  }

  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
    }
  }
  .scroller {
    position: absolute;
    right: 1rem;
    bottom: 2rem;
    color: var(--black);
    z-index: 10;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slide-bottom 0.75s cubic-bezier(0.895, 0.03, 0.685, 0.22)
      infinite alternate both;

    span {
      margin-bottom: 1rem;
      font-family: var(--font-heading);
      text-transform: uppercase;
      font-size: 1.4rem;
    }
    svg {
      font-size: 2rem;
    }
  }

  .left {
    padding: 0 2rem;
    color: var(--black);
    width: 100%;

    @media only screen and (min-width: 600px) {
      width: 50%;
    }

    @media only screen and (min-width: 1024px) {
      color: var(--white);
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 0 5rem;
    }

    .info {
      z-index: 10;
      margin-top: 30vh;

      @media only screen and (min-width: 1024px) {
        margin-top: 0vh;
      }

      .heading--xl {
        margin-bottom: 1rem;
      }

      .text {
        line-height: 1.5rem;
        font-size: 0.9rem;
        margin-bottom: 1rem;

        @media only screen and (min-width: 1024px) {
          margin-bottom: 4rem;
          max-width: 360px;
        }
      }

      .button-wrap {
        margin-bottom: 1rem;
        display: inline-flex;
        margin-right: 1rem;

        .button {
          border: 1px solid var(--black);
          color: var(--black);

          @media only screen and (min-width: 1024px) {
            border: 1px solid var(--white);
            color: inherit;
          }

          &:after {
            background: var(--white);

            @media only screen and (min-width: 1024px) {
              background: var(--brandColor);
            }
          }

          svg {
            path {
              fill: var(--black);

              @media only screen and (min-width: 1024px) {
                fill: var(--white);
              }
            }
          }
        }
      }

      .social {
        padding: 0;
        list-style: none;
        position: relative;
        z-index: 10;
        margin-top: 1.5rem;

        @media only screen and (min-width: 1024px) {
          margin-top: 3rem;
        }

        li {
          display: inline-block;
          margin-right: 1rem;

          a {
            transition: 0.5s all ease-in-out;
            position: relative;
            display: block;
            color: var(--black);

            @media only screen and (min-width: 1024px) {
              color: var(--white);
            }

            &:hover {
              color: var(--white);
              transform: scale(1.2);
              @media only screen and (min-width: 1024px) {
                color: var(--brandColor);
              }
            }
          }
        }
      }
    }
  }
  .image-wrap {
    width: 100%;
    height: 30vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;

    @media only screen and (min-width: 1024px) {
      /* opacity:0.4; */
      height: 100%;
    }

    .gatsby-image-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1 !important;
        @media only screen and (min-width: 1024px) {
          opacity: 1 !important;
        }
      }
    }
  }

  .right {
    padding: 2rem 2rem 0rem 2rem;
    width: 100%;

    &.l-in {
      padding: 2rem 2rem 4rem 2rem;

      @media only screen and (min-width: 600px) {
        padding: 2rem;
      }

      @media only screen and (min-width: 1024px) {
        padding: 2rem 8rem;
      }
    }

    @media only screen and (min-width: 600px) {
      padding: 0 2rem;
      width: 50%;
      margin-top: 30vh;
    }

    @media only screen and (min-width: 1024px) {
      padding: 0 8rem;
      background: var(--brandColor);
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      margin-top: 0vh;
    }

    .address,
    .phone {
      margin-bottom: 2rem;
      font-family: var(--font-bold);
      font-size: 1rem;
      max-width: 330px;
    }

    .address {
      a {
        color: var(--black);
        text-decoration: none;
      }
    }

    .basicprofile {
      p {
        line-height: 1.5rem;
        font-size: 0.9rem;
        margin-bottom: 1rem;

        &::last-child {
          margin-bottom: 0;
        }
      }
    }

    .opening {
      span {
        font-family: var(--font-bold);
        margin-bottom: 1rem;
        position: relative;
        display: block;
      }

      ul {
        padding: 0;
        list-style: none;

        &.monday .monday {
          font-family: var(--font-bold);
          font-style: italic;
        }
        &.tuesday .tuesday {
          font-family: var(--font-bold);
          font-style: italic;
        }
        &.wednesday .wednesday {
          font-family: var(--font-bold);
          font-style: italic;
        }
        &.thursday .thursday {
          font-family: var(--font-bold);
          font-style: italic;
        }
        &.friday .friday {
          font-family: var(--font-bold);
          font-style: italic;
        }
        &.saturday .saturday {
          font-family: var(--font-bold);
          font-style: italic;
        }
        &.sunday .sunday {
          font-family: var(--font-bold);
          font-style: italic;
        }

        li {
          margin-bottom: 0.5rem;
          font-size: 0.9rem;
        }
      }
    }

    .address,
    .phone,
    .opening {
      position: relative;
      display: block;
      padding: 0 0 0 2rem;
      line-height: 1.5rem;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }
    }

    .claim-business-wrap,
    .offer-wrap {
      margin-top: 2rem;
      padding: 2rem;
      border: 2px solid var(--black);

      .heading {
        color: var(--black);
        font-family: var(--font-bold);
        text-transform: uppercase;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        transition: 0.5s all ease-in-out;
      }
    }

    .claim-business-wrap {
      transition: 0.5s all ease-in-out;
      cursor: pointer;

      &:hover {
        color: var(--brandColor);
        background: var(--black);

        .heading {
          color: var(--brandColor);
        }
      }
    }

    .userLogin {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      border-radius: 100%;
      border: 2px solid var(--black);
      /* background:var(--black); */
      cursor: pointer;
      transition: 0.5s all ease-in-out;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 30;
      background: none;

      &:focus {
        outline: none;
      }

      svg {
        width: 30px;
        height: 30px;

        path {
          transition: 0.5s all ease-in-out;
        }
      }

      &.active {
        background: var(--brandColor);
        border: 2px solid var(--brandColor);

        svg path {
          stroke: var(--black);
        }
      }
      &:hover {
        background: var(--black);

        svg path {
          stroke: var(--brandColor);
        }
      }
    }
  }
`

export default function HeroDefault(data) {
  const listing = data.data.post
  const today = new Date().toLocaleString("en-us", { weekday: "long" })
  const loggedIn = data.loggedIn
  const [profileFormActive, setProfileFormActive] = useState(false)
  const userLogin = createRef()
  const [claimBusinessModalDisplay, setClaimBusinessModalDisplay] =
    useState(false)

  const wrapperStyles = {
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "0",
    left: "0",
  }

  const updateProfileState = () => {
    if (profileFormActive === false) {
      setProfileFormActive(true)
      userLogin.current.classList.add("active")
    } else {
      setProfileFormActive(false)
      userLogin.current.classList.remove("active")
    }
  }

  const toggleClaimBusinessModal = () => {
    setClaimBusinessModalDisplay(!claimBusinessModalDisplay)
  }

  return (
    <div>
      <Hero>
        <div className="scroller">
          <span>Scroll</span> <BsArrowDown />
        </div>
        <div className="left">
          <div className="info">
            <h2 className="heading--xl">{listing.title}</h2>
            <div className="text">{listing.acf_listings.taglineSummary}</div>
            {listing.acf_listings.websiteUrl &&
            listing.reduceToBasicListing.profileType === "full" ? (
              <div className="button-wrap">
                <ButtonExternal
                  color="white"
                  bg="brandColor"
                  text={`View website`}
                  link={listing.acf_listings.websiteUrl}
                />
              </div>
            ) : (
              ""
            )}
            {listing.acf_listings.secondaryButtonText &&
            listing.acf_listings.secondaryButtonLink &&
            listing.reduceToBasicListing.profileType === "full" ? (
              <div className="button-wrap">
                <ButtonExternal
                  color="white"
                  bg="brandColor"
                  text={listing.acf_listings.secondaryButtonText}
                  link={listing.acf_listings.secondaryButtonLink}
                />
              </div>
            ) : (
              ""
            )}
            <ul className="social">
              {listing.acf_listings.twitterUrl ? (
                <li>
                  <a
                    href={listing.acf_listings.twitterUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <GrTwitter
                      role="button"
                      aria-label="View Twitter Profile"
                    />
                  </a>
                </li>
              ) : (
                ""
              )}
              {listing.acf_listings.facebookUrl ? (
                <li>
                  <a
                    href={listing.acf_listings.facebookUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <GrFacebook
                      role="button"
                      aria-label="View Facebook Profile"
                    />
                  </a>
                </li>
              ) : (
                ""
              )}
              {listing.acf_listings.instagramUrl ? (
                <li>
                  <a
                    href={listing.acf_listings.instagramUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <GrInstagram
                      role="button"
                      aria-label="View Instagram Profile"
                    />
                  </a>
                </li>
              ) : (
                ""
              )}
              {listing.acf_listings.youtubeUrl ? (
                <li>
                  <a
                    href={listing.acf_listings.youtubeUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <GrYoutube
                      role="button"
                      aria-label="View Youtube Profile"
                    />
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
          <div className="image-wrap">
            <RellaxWrapper speed={-1.25} style={wrapperStyles}>
              <GatsbyImage
                image={
                  listing?.acf_listings?.image1?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                objectPosition={`${
                  listing?.acf_listings?.image1FocalPoint.x !== null
                    ? listing?.acf_listings?.image1FocalPoint.x
                    : 50
                }% ${
                  listing?.acf_listings?.image1FocalPoint.y !== null
                    ? listing?.acf_listings?.image1FocalPoint.y
                    : 50
                }%`}
              />
            </RellaxWrapper>
          </div>
        </div>
        <div className={loggedIn === true ? `l-in right` : `right`}>
          {listing.acf_listings.address ? (
            <div className="address">
              {listing.acf_listings.googleMapLocationUrl ? (
                <a
                  href={listing.acf_listings.googleMapLocationUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Marker /> <span>{listing.acf_listings.address}</span>
                </a>
              ) : (
                <div>
                  <Marker /> <span>{listing.acf_listings.address}</span>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          {listing.acf_listings.phone ? (
            <div className="phone">
              <Phone /> <span>{listing.acf_listings.phone}</span>
            </div>
          ) : (
            ""
          )}
          <div className="opening">
            {listing.acf_listings.mondayOpen &&
            listing.acf_listings.tuesdayOpen &&
            listing.acf_listings.wednesdayOpen &&
            listing.acf_listings.thursdayOpen &&
            listing.acf_listings.fridayOpen &&
            listing.acf_listings.saturdayOpen &&
            listing.acf_listings.sundayOpen ? (
              <div>
                <Clock /> <span>Opening hours</span>
                <ul className={today.toLowerCase()}>
                  {listing.acf_listings.mondayOpen &&
                  listing.acf_listings.mondayClose ? (
                    <li className="monday">
                      Mon: {listing.acf_listings.mondayOpen} -{" "}
                      {listing.acf_listings.mondayClose}
                    </li>
                  ) : (
                    ""
                  )}
                  {listing.acf_listings.tuesdayOpen &&
                  listing.acf_listings.tuesdayClose ? (
                    <li className="tuesday">
                      Tues: {listing.acf_listings.tuesdayOpen} -{" "}
                      {listing.acf_listings.tuesdayClose}
                    </li>
                  ) : (
                    ""
                  )}
                  {listing.acf_listings.wednesdayOpen &&
                  listing.acf_listings.wednesdayClose ? (
                    <li className="wednesday">
                      Wed: {listing.acf_listings.wednesdayOpen} -{" "}
                      {listing.acf_listings.wednesdayClose}
                    </li>
                  ) : (
                    ""
                  )}
                  {listing.acf_listings.thursdayOpen &&
                  listing.acf_listings.thursdayClose ? (
                    <li className="thursday">
                      Thurs: {listing.acf_listings.thursdayOpen} -{" "}
                      {listing.acf_listings.thursdayClose}
                    </li>
                  ) : (
                    ""
                  )}
                  {listing.acf_listings.fridayOpen &&
                  listing.acf_listings.fridayClose ? (
                    <li className="friday">
                      Fri: {listing.acf_listings.fridayOpen} -{" "}
                      {listing.acf_listings.fridayClose}
                    </li>
                  ) : (
                    ""
                  )}
                  {listing.acf_listings.saturdayOpen &&
                  listing.acf_listings.saturdayClose ? (
                    <li className="saturday">
                      Sat: {listing.acf_listings.saturdayOpen} -{" "}
                      {listing.acf_listings.saturdayClose}
                    </li>
                  ) : (
                    ""
                  )}
                  {listing.acf_listings.sundayOpen &&
                  listing.acf_listings.sundayClose ? (
                    <li className="sunday">
                      Sun: {listing.acf_listings.sundayOpen} -{" "}
                      {listing.acf_listings.sundayClose}
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
          {listing.reduceToBasicListing.profileType === "basic" ? (
            <div className="basicprofile">
              {listing.acf_listings.fullText
                ? parse(listing.acf_listings.fullText)
                : ""}
            </div>
          ) : (
            ""
          )}

          {listing.acf_listings.blackCardPromotion ? (
            <div className="offer-wrap">
              <div className="heading">Offer</div>
              <div className="offer">
                {parse(listing.acf_listings.blackCardPromotion)}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* {
                    listing.reduceToBasicListing.profileType === 'basic' ?
                        <button className="claim-business-wrap" onClick={toggleClaimBusinessModal} onKeyDown={toggleClaimBusinessModal}>
                            <div className="heading">Are you the owner of this business?</div>
                            <div className="offer">Click here to claim your business profile.</div>
                        </button>
                        : ""
                    } */}
          {loggedIn === true ? (
            <button
              className="userLogin"
              ref={userLogin}
              title="Edit your profile"
              onClick={updateProfileState}
              onKeyDown={updateProfileState}
            >
              <GrContactInfo />
            </button>
          ) : (
            ""
          )}
          {loggedIn === true ? (
            <ProfileEditorForm listing={listing} isActive={profileFormActive} />
          ) : (
            ""
          )}
        </div>
      </Hero>
      <ClaimBusinessModal
        showModal={claimBusinessModalDisplay}
        setShowModal={toggleClaimBusinessModal}
        listingTitle={listing.title}
      />
    </div>
  )
}
