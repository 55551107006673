import React, { Component } from "react"
import AnimatedImage from "../global/AnimatedImage"
import parse from "html-react-parser"
import Slider from "react-slick"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Wrapper = styled.div`
  padding: 4rem 2rem !important;
  margin-bottom: -2rem;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: 1024px) {
    padding: 8rem 2rem 2rem 2rem !important;
    flex-wrap: nowrap;
  }
`
const ContentWrap = styled.div`
  width: 100%;
  padding: 0;
  position: relative;
  order: 1;
  margin-bottom: 2rem;

  @media only screen and (min-width: 1024px) {
    width: 50%;
    margin-bottom: 0;
    padding: 0 0 0 4rem;
    order: 2;
  }

  .fullText {
    width: 100%;
    margin-bottom: 2rem;
    position: relative;

    @media only screen and (min-width: 1024px) {
      margin-bottom: 4rem;
    }

    p {
      line-height: 1.5rem;
      margin-bottom: 1rem;
      font-size: 0.9rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .fact {
    width: 100%;
    position: relative;

    .factTitle {
      font-size: 1.4rem;
      font-family: var(--font-bold);
      margin-bottom: 1rem;
    }

    .factAnswer {
      font-size: 0.9rem;
      line-height: 1.5rem;

      p {
        line-height: 1.5rem;
        margin-bottom: 1rem;
        font-size: 0.9rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`

const SliderWrap = styled.div`
  width: 100%;
  padding-bottom: 56%;
  position: relative;
  display: block;
  order: 2;
  margin-bottom: 2rem;

  @media only screen and (min-width: 1024px) {
    padding-bottom: 35%;
    margin-bottom: 0;
    width: 50%;
    order: 1;
  }

  .slick-slider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /*
        &:after{
        width:100%;
        height:100%;
        position: absolute;
        top:2rem;
        left:2rem;
        content:"";
        background:var(--brandColor);
        z-index:-1
        } */

    .slick-list {
      width: 100%;
      height: 100%;
      overflow: visible;

      .slick-track {
        height: 100% !important;

        .slick-slide {
          div {
            width: 100% !important;
            height: 100% !important;
            padding-bottom: 1rem;
          }

          .image-inner:after {
            transform: translate(-1rem, -1rem);
          }
          &.slick-active {
            .image-inner:after {
              transform: translate(0, 0);
              /* transform:translate(0.5rem,1rem); */
            }
          }
        }
      }
    }
  }

  .slide {
    width: 100%;
    height: 100%;
    position: relative;

    .gatsby-image-wrapper {
      width: 100% !important;
      height: 100% !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;

      img {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        object-fit: cover !important;
      }
    }
  }
`

export default class AdditionalListingsInfo extends Component {
  state = {
    slideIndex: 0,
    updateCount: 0,
  }
  render() {
    const listing = this.props.data.post
    return (
      <Wrapper className="wrapper">
        {listing?.acf_listings?.image2 || listing?.acf_listings?.image3 ? (
          <SliderWrap className="left">
            <Slider
              ref={slider => (this.slider = slider)}
              dots={false}
              infinite={true}
              fade={true}
              speed={500}
              arrows={false}
              slidesToShow={1}
              autoplay={true}
              slidesToScroll={1}
            >
              {listing?.acf_listings?.image2?.localFile?.childImageSharp
                ?.gatsbyImageData ? (
                <div className="slide">
                  <AnimatedImage
                    imgSrc={
                      listing?.acf_listings?.image2?.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                    objectPosition={`${
                      listing?.acf_listings?.image2FocalPoint.x !== null
                        ? listing?.acf_listings?.image2FocalPoint.x
                        : 50
                    }% ${
                      listing?.acf_listings?.image2FocalPoint.y !== null
                        ? listing?.acf_listings?.image2FocalPoint.y
                        : 50
                    }%`}
                    offsetX={"3rem"}
                    offsetY={"3rem"}
                    color={"brandColor"}
                  />
                  {/* <Img fluid={listing?.acf_listings?.image2?.localFile?.childImageSharp?.gatsbyImageData} /> */}
                </div>
              ) : (
                ""
              )}
              {listing?.acf_listings?.image3?.localFile?.childImageSharp
                ?.gatsbyImageData ? (
                <div className="slide">
                  <AnimatedImage
                    imgSrc={
                      listing?.acf_listings?.image3?.localFile?.childImageSharp
                        ?.gatsbyImageData
                    }
                    objectPosition={`${
                      listing?.acf_listings?.image3FocalPoint.x !== null
                        ? listing?.acf_listings?.image3FocalPoint.x
                        : 50
                    }% ${
                      listing?.acf_listings?.image3FocalPoint.y !== null
                        ? listing?.acf_listings?.image3FocalPoint.y
                        : 50
                    }%`}
                    offsetX={"3rem"}
                    offsetY={"3rem"}
                    color={"brandColor"}
                  />
                  {/* <Img fluid={listing?.acf_listings?.image3?.localFile?.childImageSharp?.gatsbyImageData} /> */}
                </div>
              ) : (
                ""
              )}
            </Slider>
          </SliderWrap>
        ) : (
          ""
        )}
        <ContentWrap className="right">
          <div className="fullText">
            {listing.acf_listings.fullText
              ? parse(listing.acf_listings.fullText)
              : ""}
          </div>
          {listing.acf_listings.theFact &&
          listing.acf_listings.tellUsYourFact ? (
            <div className="fact">
              <div className="factTitle">{listing.acf_listings.theFact}</div>
              <div className="factAnswer">
                {listing.acf_listings.tellUsYourFact
                  ? parse(listing.acf_listings.tellUsYourFact)
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
        </ContentWrap>
      </Wrapper>
    )
  }
}
