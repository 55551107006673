import React , {useState}from 'react'
import styled from 'styled-components';
import {GatsbyImage} from 'gatsby-plugin-image'
import ButtonForm from '../global/ButtonForm'

const EditorForm = styled.div`
width:100%;
height:100%;
background:var(--black);
position:absolute;
top:0;
right:0;
transform:translateY(${props => props.translate});
z-index:20;
transition:0.5s all ease-in-out;
color:var(--white);
padding:2rem 4rem 4rem 4rem;
overflow:scroll;

    ul{
    list-style: none;

        .heading--m{
        margin-bottom:2rem;
        margin-top:2rem;
        }
    
        li{
        width:100%;
        display:flex;
        flex-wrap:wrap;
        justify-content:flex-start;
        margin-bottom:1rem;

            label{
            width:100%;
            margin-bottom:0.5rem;
            font-size:0.9rem;
            }

            select,
            input[type="text"],
            textarea{
            padding:0.5rem;
            width:90%;

                &:focus{
                outline:none;
                }

            }
            
            &.openingHours{
                input[type="text"]{
                width:calc(45% - 1rem);

                    &:first-of-type{
                    margin-right:2rem;
                    }

                }
            }
        
        }

        .image-wrapper{
        width:100px;
        height:100px;
        margin-bottom:1rem;
        margin-right:1rem;
        overflow:hidden;
        position:relative;

            .gatsby-image-wrapper{
            width:100% !important;
            height:100%  !important;
            position:absolute  !important;
            top:0  !important;
            left:0 !important;

                img{
                width:100% !important;
                height:100%  !important;
                position:absolute  !important;
                top:0  !important;
                left:0 !important;
                object-fit:cover !important;
                }
            }

            .output{
            width: 100px;
            height: 100px;
            position: relative;
            object-fit: cover;

                &[src=""]{
                    display: none;  
                }

            }
        }

        .upload-wrapper{
        display: flex;
        flex-wrap: wrap;
        align-content:flex-start;
        }


    }

`

export default function ProfileEditorForm({listing, isActive}) {
    const isClient = typeof document !== 'undefined' && typeof window !== 'undefined';

    function getText(html){
        if(html){
            html = html.replace(/[&]nbsp[;]/gi," ");
            html = html.replace(/[<]br[^>]*[>]/gi,"");
            html = html.replace(/(\r\n|\n|\r)/gm," ");
            html = html.replace(/((<)p+(>))/gi,"");
            html = html.replace(/((<\/)\p+(>))/gi,"\n \n");
            if(isClient){
                var divContainer= document.createElement("div");
                divContainer.innerHTML = html;
                return divContainer.textContent || divContainer.innerText || "";
            }
        }
    }

    
    function readImage(file, targetOutput) {
        if(isClient){    
            const output = document.getElementById(targetOutput);
            // Check if the file is an image.
            if (file.type && file.type.indexOf('image') === -1) {
            return;
            }
        
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                output.src = event.target.result;
            });
            reader.readAsDataURL(file);
        }
    }


    const [businessName, setBusinessName] = useState(listing.title)
    const [tagline, setTagline] = useState(listing.acf_listings.taglineSummary)
    const [fullText, setFullText] = useState(getText(listing.acf_listings.fullText))
    const [offers, setOffers] = useState(listing.acf_listings.blackCardPromotion)
    const [fact, setFact] = useState(listing.acf_listings.theFact)
    const [factAnswer, setFactAnswer] = useState(listing.acf_listings.tellUsYourFact)
    const [address, setAddress] = useState(listing.acf_listings.address)
    const [phone, setPhone] = useState(listing.acf_listings.phone)
    const [email, setEmail] = useState(listing.acf_listings.email)
    const [website, setWebsite] = useState(listing.acf_listings.websiteUrl)
    const [twitter, setTwitter] = useState(listing.acf_listings.twitterUrl)
    const [facebook, setFacebook] = useState(listing.acf_listings.facebookUrl)
    const [instagram, setInstagram] = useState(listing.acf_listings.instagramUrl)
    const [youtube, setYoutube] = useState(listing.acf_listings.youtubeUrl)
    const [mondayOpen, setMondayOpen] = useState(listing.acf_listings.mondayOpen)
    const [mondayClose, setMondayClose] = useState(listing.acf_listings.mondayClose)
    const [tuesdayOpen, setTuesdayOpen] = useState(listing.acf_listings.tuesdayOpen)
    const [tuesdayClose, setTuesdayClose] = useState(listing.acf_listings.tuesdayClose)
    const [wednesdayOpen, setWednesdayOpen] = useState(listing.acf_listings.wednesdayOpen)
    const [wednesdayClose, setWednesdayClose] = useState(listing.acf_listings.wednesdayClose)
    const [thursdayOpen, setThursdayOpen] = useState(listing.acf_listings.thursdayOpen)
    const [thursdayClose, setThursdayClose] = useState(listing.acf_listings.thursdayClose)
    const [fridayOpen, setFridayOpen] = useState(listing.acf_listings.fridayOpen)
    const [fridayClose, setFridayClose] = useState(listing.acf_listings.fridayClose)
    const [saturdayOpen, setSaturdayOpen] = useState(listing.acf_listings.saturdayOpen)
    const [saturdayClose, setSaturdayClose] = useState(listing.acf_listings.saturdayClose)
    const [sundayOpen, setSundayOpen] = useState(listing.acf_listings.sundayOpen)
    const [sundayClose, setSundayClose] = useState(listing.acf_listings.sundayClose)
    const [orderOnlineButtonText, setorderOnlineButtonText] = useState(listing.acf_listings.supportLocalButtonText)
    const [orderOnlineButtonLink, setorderOnlineButtonLink] = useState(listing.acf_listings.supportLocalButtonLink)
    const [image1, setImage1] = useState(listing.acf_listings.image1 ? listing?.acf_listings?.image1?.localFile?.childImageSharp?.gatsbyImageData : "")
    const [image2, setImage2] = useState(listing.acf_listings.image2 ? listing?.acf_listings?.image2?.localFile?.childImageSharp?.gatsbyImageData : "")
    const [image3, setImage3] = useState(listing.acf_listings.image3 ? listing?.acf_listings?.image3?.localFile?.childImageSharp?.gatsbyImageData : "")
    const [updatedFields, setUpdatedFields] = useState([])
    
    
    
    return (
        <EditorForm 
            translate={isActive === false ? '-100%' : '0' }
        >
            <form action="https://getform.io/f/f9165521-0b6c-4dd5-aacd-7fc4065d8c39" method="POST" encType="multipart/form-data">
            <ul>
                <div className="heading--m">Main info</div>
                <li>
                    <label htmlFor="businessName">Business Name</label>
                    <input id="businessName" type="text" value={businessName !== null ? businessName : ""} name="Business Name" onChange={(e) => {setBusinessName(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                </li>
                <li>
                    <label htmlFor="taglineSummary">Tagline / Summary</label>
                    <textarea rows="5" id="taglineSummary" value={tagline !== null ? tagline : ""} name="Tagline / Summary" onChange={(e) => {setTagline(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>                
                <li>
                    <label htmlFor="fullText">Full Business Description</label>
                    <textarea rows="10" id="fullText" value={fullText !== null ? fullText : ""} name="Full Business Description" onChange={(e) => {setFullText(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>  
                
                <div className="heading--m">Additional info</div>
                <li>
                    <label htmlFor="offers">Available offers</label>
                    <textarea rows="10" id="offers" value={offers !== null ? offers : ""} name="Available offers" onChange={(e) => {setOffers(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>  
                <li>
                    <label htmlFor="fact">Interesting fact</label>
                    <select name="Business Fact" id="fact" value={fact !== null ? fact : ""} onChange={(e) => {setFact(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}>
                        <option value="Did you know">Did you know</option>
                        <option value="Why not try">Why not try</option>
                        <option value="Look out for">Look out for</option>
                    </select>
                </li>
                <li>
                    <label htmlFor="factAnswer">Interesting fact answer</label>
                    <textarea rows="10" id="factAnswer" value={factAnswer !== null ? factAnswer : ""} name="Interesting fact answer" onChange={(e) => {setFactAnswer(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>  

                <div className="heading--m">Contact info</div> 
                <li>
                    <label htmlFor="address">Address</label>
                    <input id="address" type="text" value={address ? address : ''} name="Address" onChange={(e) => {setAddress(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li>
                    <label htmlFor="phone">Phone</label>
                    <input id="phone" type="text" value={phone ? phone : ''} name="Phone" onChange={(e) => {setPhone(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                </li>
                <li>
                    <label htmlFor="email">Email</label>
                    <input id="email" type="text" value={email ? email : ''} name="Email" onChange={(e) => {setEmail(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li>
                    <label htmlFor="website">Website</label>
                    <input id="website" type="text" value={website ? website : ''} name="Website" onChange={(e) => {setWebsite(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>

                <div className="heading--m">Social Media</div> 
                <li>
                    <label htmlFor="twitter">Twitter</label>
                    <input id="twitter" type="text" value={twitter ? twitter : ''} name="Twitter" onChange={(e) => {setTwitter(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li>
                    <label htmlFor="facebook">Facebook</label>
                    <input id="facebook" type="text" value={facebook ? facebook : ''} name="Facebook" onChange={(e) => {setFacebook(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                </li>
                <li>
                    <label htmlFor="instagram">Instagram</label>
                    <input id="instagram" type="text" value={instagram ? instagram : ''} name="Instagram" onChange={(e) => {setInstagram(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li>
                    <label htmlFor="youtube">Youtube</label>
                    <input id="youtube" type="text" value={youtube ? youtube : ''} name="Youtube" onChange={(e) => {setYoutube(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>

                <div className="heading--m">Opening Hours</div> 
                <li className="openingHours">
                    <label htmlFor="mondayOpen">Monday</label>
                    <input id="mondayOpen" type="text" value={mondayOpen ? mondayOpen : ''} placeholder={mondayOpen ? "" : 'Opens at (e.g 9:00)'} name="Monday Open" onChange={(e) => {setMondayOpen(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                    <input id="mondayClose" type="text" value={mondayClose ? mondayClose : ''} placeholder={mondayClose ? "" : 'Closes at (e.g 17:00)'} name="Monday Close" onChange={(e) => {setMondayClose(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li className="openingHours">
                    <label htmlFor="tuesdayOpen">Tuesday</label>
                    <input id="tuesdayOpen" type="text" value={tuesdayOpen ? tuesdayOpen : ''} placeholder={tuesdayOpen ? "" : 'Opens at (e.g 9:00)'} name="Tuesday Open" onChange={(e) => {setTuesdayOpen(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                    <input id="tuesdayClose" type="text" value={tuesdayClose ? tuesdayClose : ''} placeholder={tuesdayClose ? "" : 'Closes at (e.g 17:00)'} name="Tuesday Close" onChange={(e) => {setTuesdayClose(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li className="openingHours">
                    <label htmlFor="wednesdayOpen">Wednesday</label>
                    <input id="wednesdayOpen" type="text" value={wednesdayOpen ? wednesdayOpen : ''} placeholder={wednesdayOpen ? "" : 'Opens at (e.g 9:00)'} name="Wednesday Open" onChange={(e) => {setWednesdayOpen(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                    <input id="wednesdayClose" type="text" value={wednesdayClose ? wednesdayClose : ''} placeholder={wednesdayClose ? "" : 'Closes at (e.g 17:00)'} name="Wednesday Close" onChange={(e) => {setWednesdayClose(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li className="openingHours">
                    <label htmlFor="thursdayOpen">Thursday</label>
                    <input id="thursdayOpen" type="text" value={thursdayOpen ? thursdayOpen : ''} placeholder={thursdayOpen ? "" : 'Opens at (e.g 9:00)'} name="Thursday Open" onChange={(e) => {setThursdayOpen(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                    <input id="thursdayClose" type="text" value={thursdayClose ? thursdayClose : ''} placeholder={thursdayClose ? "" : 'Closes at (e.g 17:00)'} name="Thursday Close" onChange={(e) => {setThursdayClose(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li className="openingHours">
                    <label htmlFor="fridayOpen">Friday</label>
                    <input id="fridayOpen" type="text" value={fridayOpen ? fridayOpen : ''} placeholder={fridayOpen ? "" : 'Opens at (e.g 9:00)'} name="Friday Open" onChange={(e) => {setFridayOpen(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                    <input id="fridayClose" type="text" value={fridayClose ? fridayClose : ''} placeholder={fridayClose ? "" : 'Closes at (e.g 17:00)'} name="Friday Close" onChange={(e) => {setFridayClose(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li className="openingHours">
                    <label htmlFor="saturdayOpen">Saturday</label>
                    <input id="saturdayOpen" type="text" value={saturdayOpen ? saturdayOpen : ''} placeholder={saturdayOpen ? "" : 'Opens at (e.g 9:00)'} name="Saturday Open" onChange={(e) => {setSaturdayOpen(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                    <input id="saturdayClose" type="text" value={saturdayClose ? saturdayClose : ''} placeholder={saturdayClose ? "" : 'Closes at (e.g 17:00)'} name="Saturday Close" onChange={(e) => {setSaturdayClose(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                <li className="openingHours">
                    <label htmlFor="sundayOpen">Sunday</label>
                    <input id="sundayOpen" type="text" value={sundayOpen ? sundayOpen : ''} placeholder={sundayOpen ? "" : 'Opens at (e.g 9:00)'} name="Sunday Open" onChange={(e) => {setSundayOpen(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                    <input id="sundayClose" type="text" value={sundayClose ? sundayClose : ''} placeholder={sundayClose ? "" : 'Closes at (e.g 17:00)'} name="Sunday Close" onChange={(e) => {setSundayClose(e.target.value)}}  onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}}/>
                </li>
                
                
                <div className="heading--m">Order Online</div>
                {/* <li>
                    <label htmlFor="orderOnlineMain">Unlock Local Main Info</label>
                    <textarea rows="10" id="orderOnlineMain" value={orderOnlineMain !== null ? orderOnlineMain : ""} name="Unlock Local Main Info" onChange={(e) => {setorderOnlineMain(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                </li>
                <li>
                    <label htmlFor="orderOnlineShort">Unlock Local Shortened Info</label>
                    <textarea rows="5" id="orderOnlineShort" value={orderOnlineShort !== null ? orderOnlineShort : ""} name="Unlock Local Shortened Info" onChange={(e) => {setorderOnlineShort(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                </li>    */}
                <li>
                    <label htmlFor="orderOnlineButtonText">Order Online Button Text</label>
                    <input id="orderOnlineButtonText" type="text" value={orderOnlineButtonText !== null ? orderOnlineButtonText : ""} name="Unlock Local Button Text" onChange={(e) => {setorderOnlineButtonText(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                </li>
                <li>
                    <label htmlFor="orderOnlineButtonLink">Order Online Button Link</label>
                    <input id="orderOnlineButtonLink" type="text" value={orderOnlineButtonLink !== null ? orderOnlineButtonLink : ""} name="Unlock Local Button Link" onChange={(e) => {setorderOnlineButtonLink(e.target.value)}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                </li>

                <div className="heading--m">Images</div>
                
                
                    <li>
                        <div className="image-wrapper">
                            {image1 ? <GatsbyImage image={image1} /> : "" }
                            <img id="image1-output" src="" className="output" alt="Business Profile 1"/>
                        </div>
                        <div className="upload-wrapper">
                            <label htmlFor="image-1">Image 1</label>
                            <input type="file" name="Image 1" id="image-1" onChange={(e) => {readImage(e.target.files[0], 'image1-output'); setImage1(e.target.files[0])}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                        </div>
                    </li>
                
                <li>
                    <div className="image-wrapper">
                        {image2 ? <GatsbyImage image={image2} /> : "" }
                        <img id="image2-output" src="" className="output" alt="Business Profile 2"/>
                    </div>
                    <div className="upload-wrapper">
                        <label htmlFor="image-2">Image 2</label>
                        <input type="file" name="Image 2" id="image-2" onChange={(e) => {readImage(e.target.files[0], 'image2-output'); setImage2(e.target.files[0])}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                    </div>
                </li>
                <li>
                    <div className="image-wrapper">
                        {image3 ? <GatsbyImage image={image3} /> : "" }
                        <img id="image3-output" src="" className="output" alt="Business Profile 3"/>
                    </div>
                    <div className="upload-wrapper">
                        <label htmlFor="image-3">Image 3</label>
                        <input type="file" name="Image 3" id="image-3" onChange={(e) => {readImage(e.target.files[0], 'image3-output'); setImage3(e.target.files[0])}} onBlur={(e) => {setUpdatedFields(updatedFields +e.target.name+', ')}} />
                    </div>
                </li>
                <li className="hidden">    
                    <label htmlFor="updatedFields">Updated Fields</label>
                    <textarea  rows="10" id="updatedFields" defaultValue={updatedFields !== null ? updatedFields : ""} name="Updated fields"/>
                </li>
                
            </ul>

            <ButtonForm 
            buttonBgColor={'brandColor'}
            buttonBgColorHover={'white'}
            buttonTextColor={'black'}
            buttonTextColorHover={'black'}
            buttonBorderColor={'black'}
            text="Send profile updates"
            />

            </form>
        </EditorForm>
    )
}
