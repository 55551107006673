import React, {createRef, Component } from 'react'
import styled from 'styled-components'
import parse from 'html-react-parser'
import {GatsbyImage} from 'gatsby-plugin-image'
import {CgClose} from 'react-icons/cg'
import { StaticQuery, graphql } from "gatsby"
import ButtonForm from '../global/ButtonForm';

const Modal = styled.div`
opacity:${props => props.showModal === false ? "0" : "1"};
pointer-events:${props => props.showModal === false ? 'none' : 'all'};;

  .centered{
  transform:${props => props.showModal === false ? "translateY(200px)" : "translateY(0)"};
  
    .right{
      form{
        .phone,
        .name{
        margin-right:2rem;
        }
      }
    }
  }

`






export default class ClaimBusinessModal extends Component {

  constructor(props){
    super(props);
    this.state = {
    showModal: this.props.showModal
    }    
    this.modal = createRef();
  }  
  
  showModal = () => {
    this.setState({
      showModal: !this.state.showModal
    })
    
  }

  componentDidMount(){
    const isClient = typeof document !== 'undefined' && typeof window !== 'undefined';
    let header

    if(isClient){
      header = document.querySelector('#header');
      if(header){header.parentNode.after(this.modal.current)}
    }
    
    this.setOverflowOnModal();
    
  }

  componentDidUpdate(){
    this.setOverflowOnModal();
  }

  componentWillUnmount(){

  }

  setOverflowOnModal = () => {
    const outerH = this.modal.current.offsetHeight;
    const innerH = this.modal.current.querySelector('.centered .right').offsetHeight;    
  }
  
  render() {
    return (
      <StaticQuery
      query={graphql`
        query ClaimBusinessModalQuery {
            wp {
                options {
                  acf_options {
                    claimBusinessText
                    claimBusinessHeading
                    claimBusinessImage {
                      localFile{
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
            }
        }
      `}
      render={data => (
        <Modal className="modal" ref={this.modal} id="claimBusiness" showModal={this.props.showModal}>
            <div className="centered">
              <div className="left">
                <GatsbyImage image={data.wp.options.acf_options.claimBusinessImage.localFile.childImageSharp.gatsbyImageData} />
              </div>
              <div className="right">
                <button  aria-label="Close Popup" className="close"  onClick={this.props.setShowModal}><CgClose /></button>
                <h1 className="heading--l">{parse(data.wp.options.acf_options.claimBusinessHeading)}</h1>
                <div className="text">{parse(data.wp.options.acf_options.claimBusinessText)}</div>
                <form action="https://getform.io/f/57cf1477-687c-4f86-8f33-7c67f1bbddb6" method="POST">
                    <label className="name">
                      <span>Name</span>
                      <input type="text" name="name" required />
                    </label>
                    <label className="email">
                      <span>Email</span>
                      <input type="text" name="email" required />
                    </label>
                    <label className="phone">
                      <span>Phone</span>
                      <input type="text" name="phone" required />
                    </label>
                    <label className="listing">
                      <span>Business Name</span>
                      <input type="text" disabled defaultValue={this.props.listingTitle} name="listing" />
                    </label>
                    
                    <ButtonForm 
                        buttonBgColor={'white'}
                        buttonBgColorHover={'brandColor'}
                        buttonTextColor={'black'}
                        buttonTextColorHover={'black'}
                        buttonBorderColor={'black'}
                        text="Submit"
                        />
                  </form>
              </div>
            </div>  
        </Modal>
      )}
    />
        
 
    )
  }
}
