import React from "react"
import IndependentsHero from "../components/independents-profiles/IndependentsHero"
import AdditionalListingsInfo from "../components/independents-profiles/AdditionalListingsInfo"
import GridCardSlider from "../components/global/GridCardSlider"
import { graphql } from "gatsby"
import { getUser, isLoggedIn } from "../services/auth"
import Seo from "../components/seo"

const ListingsPostTemplate = ({ data, pageContext, next, previous }) => {
  const seo = data.seo.seo
  // If user has logged in w/ firebase and has a valid getUser localStorage,
  // and their login email matches the owner email of the current listing,
  // then let them see the listing editor form
  // otherwise dont!
  const ownerEmail = data.post.author.node.email
  let loggedIn = null
  isLoggedIn() && getUser().email === ownerEmail
    ? (loggedIn = true)
    : (loggedIn = false)

  // Reformat data for GridCardSlider

  function random_item(items, howMany) {
    let randomSelection = []
    for (let i = 1; i <= 20; i++) {
      let random = items[Math.floor(Math.random() * items.length)]

      if (!randomSelection.includes(random)) {
        randomSelection.push(random)
      }

      if (randomSelection.length === howMany) {
        return randomSelection
      }
    }
  }

  var randomRelatedPosts
  const relatedPosts = data.internalPollinate.edges
  const relatedPostsFallback = data.internalPollinateFallback.edges
  const crossPollinateData =
    data.crossPollinate.acf_cross_pollinate.chooseListings
  const crossPollinate = []

  // If cross polinate is manually specified
  // push random posts to array
  if (crossPollinateData) {
    crossPollinateData.map((item, index) => {
      return crossPollinate.push({
        title: item?.pickAListing?.title,
        excerpt: item?.pickAListing?.acf_listings?.taglineSummary,
        link:
          "https://" +
          item?.pickAListing?.acf_imported_listings?.parentCity +
          ".independentlife.co.uk/independents/" +
          item?.pickAListing?.acf_imported_listings?.slug,
        image: item?.pickAListing?.acf_listings?.image1,
        intOrExt: "external",
      })
    })
    randomRelatedPosts = random_item(
      relatedPosts,
      10 - crossPollinateData.length
    )
    randomRelatedPosts.map((item, index) => {
      return crossPollinate.push({
        title: item?.node?.title,
        linkPrefix: item?.node?.category,
        excerpt: item?.node?.acf_listings?.taglineSummary,
        link: item?.node?.slug,
        image: item?.node?.acf_listings?.image1,
        intOrExt: "internal",
      })
    })
  } else {
    if (relatedPosts.length > 5) {
      randomRelatedPosts = random_item(relatedPosts, 5)
      randomRelatedPosts.map((item, index) => {
        return crossPollinate.push({
          title: item?.node?.title,
          linkPrefix: item?.node?.category,
          excerpt: item?.node?.acf_listings?.taglineSummary,
          link: item?.node?.slug,
          image: item?.node?.acf_listings?.image1,
          intOrExt: "internal",
        })
      })
    }
    randomRelatedPosts = random_item(relatedPostsFallback, 5)
    if (randomRelatedPosts !== undefined) {
      randomRelatedPosts.map((item, index) => {
        return crossPollinate.push({
          title: item?.node?.title,
          linkPrefix: item?.node?.category,
          excerpt: item?.node?.acf_listings?.taglineSummary,
          link: item?.node?.slug,
          image: item?.node?.acf_listings?.image1,
          intOrExt: "internal",
        })
      })
    }
  }

  return (
    <div>
      <Seo
        title={seo.title}
        description={
          seo.metaDesc === ""
            ? `${data.post.title} is an independent business in ${process.env.GATSBY_SITENAME}`
            : seo.metaDesc
        }
        image={data?.post?.acf_listings?.image1?.mediaItemUrl}
      />

      <IndependentsHero data={data} loggedIn={loggedIn} />
      {data.post.reduceToBasicListing.profileType === "full" ? (
        <AdditionalListingsInfo data={data} />
      ) : (
        ""
      )}
      <GridCardSlider
        sectionTitle="Have you heard of..."
        linkPrefix={""}
        data={crossPollinate}
        areaBase={pageContext.areaBase}
        bgPosition="bottom"
        bgSize="50%"
        bgColor="grey"
      />
    </div>
  )
}

export default ListingsPostTemplate

export const pageQuery = graphql`
  query ListingsById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $category: String
    $type: String
  ) {
    seo: wpListing(id: { eq: $id }) {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }
    internalPollinate: allWpListing(
      filter: {
        listingsCategories: {
          nodes: { elemMatch: { name: { eq: $category } } }
        }
        listingsTagType: { nodes: { elemMatch: { name: { eq: $type } } } }
        id: { ne: $id }
      }
      limit: 5
    ) {
      edges {
        node {
          title
          slug
          acf_listings {
            taglineSummary
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    internalPollinateFallback: allWpListing(
      filter: {
        listingsCategories: {
          nodes: { elemMatch: { name: { eq: $category } } }
        }
        id: { ne: $id }
      }
      limit: 5
    ) {
      edges {
        node {
          title
          slug
          acf_listings {
            taglineSummary
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    crossPollinate: wpListing(id: { eq: $id }) {
      acf_cross_pollinate {
        chooseListings {
          pickAListing {
            ... on WpImported_listing {
              id
              title
              acf_imported_listings {
                parentCity
                slug
              }
              acf_listings {
                taglineSummary
                image1 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    # selecting the current post by id
    post: wpListing(id: { eq: $id }) {
      id
      title
      reduceToBasicListing {
        profileType
      }
      acf_listings {
        address
        blackCardPromotion
        email
        facebookUrl
        fridayClose
        fridayOpen
        fullText
        googleMapLocationUrl
        instagramUrl
        mondayClose
        mondayOpen
        phone
        saturdayClose
        saturdayOpen
        sundayClose
        sundayOpen
        secondaryButtonLink
        secondaryButtonText
        taglineSummary
        tellUsYourFact
        theFact
        thursdayClose
        thursdayOpen
        tuesdayClose
        tuesdayOpen
        twitterUrl
        websiteUrl
        wednesdayClose
        wednesdayOpen
        youtubeUrl
        image1 {
          mediaItemUrl
          localFile {
            absolutePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image1FocalPoint {
          x
          y
        }
        image2 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image2FocalPoint {
          x
          y
        }
        image3 {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image3FocalPoint {
          x
          y
        }
      }
      author {
        node {
          email
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
    }

    # this gets us the previous post by id (if it exists)
    previous: wpListing(id: { eq: $previousPostId }) {
      uri
      title
      slug
    }

    # this gets us the next post by id (if it exists)
    next: wpListing(id: { eq: $nextPostId }) {
      uri
      title
      slug
    }
  }
`
